<template>
    <setting-layout :title="$t('pages.user.setting.password.title')">
        <v-container>
            <text-field
                type="password"
                :placeholder="this.$t(`pages.user.setting.password.old_password`)"
                v-model="form.old_password"
                :error-messages="errors.old_password"
            />
            <text-field
                type="password"
                :placeholder="this.$t(`pages.user.setting.password.new_password`)"
                v-model="form.new_password"
                :error-messages="errors.new_password"
                class="mt-4"
            />
            <text-field
                type="password"
                :placeholder="this.$t(`pages.user.setting.password.confirm_password`)"
                v-model="form.new_password_confirmation"
                :error-messages="errors.new_password_confirmation"
                class="mt-4"
            />
            <v-row justify="center" class="px-3 pt-8">
                <gradient-button block @click="save"
                                 :loading="$store.getters.loadingBtn(['setting', 'passwordChangeBtn'])">
                    {{ $t('pages.user.setting.password.update') }}
                </gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions, mapState} from "vuex";

export default {
    name: "Password",
    data: () => ({
        form: {
            old_password: '',
            new_password: '',
            new_password_confirmation: '',
        },
        errors: {}
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
        view() {
            return JSON.stringify(this.inputs)
        }
    },
    methods: {
        save() {
            this.$mixpanel.click('Update Password', 'Settings Screen');

            this.errors = {};
            this.sendPasswordChange({model: this.form})
                .then(() => {
                    this.form = {
                        old_password: '',
                        new_password: '',
                        new_password_confirmation: '',
                    }
                    this.$store.commit('showSnackBar', this.$t('messages.password_changed'))
                })
                .catch(error => {
                    this.errors = error.response.data.errors
                })
        },
        ...mapActions(['sendPasswordChange'])
    },

    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Password update');
    },

    components: {GradientButton, TextField, SettingLayout}
}
</script>

<style scoped>

</style>
